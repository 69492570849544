const domain = 'getneosurf.com';
const siteUrl = `https://www.${domain}`;
const languages = [ 'en', 'fr', 'de' ];
const defaultLanguage = 'en';

module.exports = {
  domain,
  siteUrl,
  languages,
  defaultLanguage
};
